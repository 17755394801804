<template>
<b-container class="shlajsna">
  <b-row class="pt-5">
    <b-col sm="12" class="text-center">
      <h1>{{ $t('signin.member-login')}}</h1>
    </b-col>
    <b-col md="6" offset-md="3" lg="4" offset-lg="4">
      <b-alert v-if="loginError" variant="danger" :text="loginError"></b-alert>
      
      <div class="my-3">{{ message }}</div>
      <p>
         <input v-model="email" type="text" class="form-control" name="username" placeholder="Email" required="required">
       </p>
       <p>
         <input v-model="password" type="password" class="form-control" name="password" placeholder="Password" required="required">
       </p>
       <div class="text-center">
         <button type="submit" class="btn btn-primary btn-lg" @click="memberLogin">Login</button>
       </div>

    </b-col>
  </b-row>
</b-container>
</template>

<script>
export default {
  name: 'Login',
  components : {},

  data: function() {
    return {
      lang: this.$route.params.lang,
      loginError : "",
      email: "",
      password: "",
      loggedIn : false,
      showSpinner: false,
      message: this.$route.params.message
    }
  },

  created: function() {
    // console.log(this.lang);
    this.removeAuthData();
    if (typeof this.lang === "undefined") {
      // Ako nema jezika u URL parametru, preuzima se jezik iz storage-a.
      this.lang = this.decodeJSON(window.localStorage.getItem("dmn_sl"));
      console.log("Izabrani jezik je: " + this.lang);
    }
  },

  methods: {
    memberLogin: function (event) {
      event.preventDefault();
      this.showSpinner = true;
      this.$http.post('/api/login', { email : this.email, password: this.password }).then(r => {
        if (r.headers.authorization !== null) {
          window.localStorage.setItem("AccessToken", r.headers.authorization);
          window.localStorage.setItem("Email", 	r.headers.email);
          window.localStorage.setItem("AccessTokenExpire", r.headers.token_expiration_time);
          this.getUserData();
        }
       }).catch(error => {
        this.handleError(error);
       })
       this.showSpinner = false;
    },

    getUserData() {
      if (this.getAuthData() != null) {
        // console.log("Imam podatke!");
        if (window.localStorage.getItem("dmn_user") === null) {
            // console.log("Nemam korisnika!");
          this.$http.get('/cms/api/users/get/email/' + this.getAuthData().email + "/" + this.$root.lang.IANA, { headers: {Authorization: this.getAuthData().accessToken}}).then(r => {
            this.user = r.data
            window.localStorage.setItem("dmn-user", this.encodeJSON(r.data));
            this.$root.user = r.data;
            this.setNewLanguage(r.data.language);
            // console.log(this.$router);
            // console.log("Login završen. Vršim preusmeravanje.");
            // console.log(this.$route.query.redirect || '/');
            this.$router.push({ name: "Dashboard" });
          }).catch(e => {
            console.log("Neka greška je u pitanju!")
            this.handleError(e);
          })
        } else {
          this.user = this.decodeJSON(window.localStorage.getItem("dmn_user"));
          this.$root.user = this.user;
        }
      }
    }
  }

}
</script>
<style></style>
